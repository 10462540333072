import React, { Component } from "react";
import axios from 'axios';

export class Goals extends Component {

    constructor(props) {
        super(props);
        this.state = { 
                        username: "",
                        income: "", 
                        dolPerKw: "", 
                        aveKwPerInstall: "", 
                        aveDolPerInstall: "",
                        installsNeeded: "",
                        stickPercentage: "",
                        salesNeeded: "",
                        sitsPerSale: "",
                        sitsNeeded: "",
                        apptsPerSit: "",
                        apptsNeeded: "",
                        convsPerApt: "",
                        convsNeeded: "",
                        doorsPerConvs: "",
                        doorsNeeded: "",
                        doorsPerHour: "",
                        hoursNeeded: "",
                        hoursPerWeek: "",
                        hoursPerDay: "",
                        date: "",
                        time: "",
                        receiverEmail: ""
                      }
    
        this.handleChange = this.handleChange.bind(this);
        this.sendData = this.sendData.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    sendData() {
      axios
        .post((process.env.REACT_APP_ENV === "development") ? 'http://localhost:9000/append' : '/append', { ...this.state })
        .then(response => {
          console.log(response.data, 'response data');
          if (response.data.msg === "success"){
            alert("Data saved, thanks!");
          } else if(response.data.msg === "error"){
            alert("Oops, something went wrong. Please try again.")
          }
        })

        .catch(() => {
          console.log({
            success: false,
            message: 'Something went wrong. Try again later'
          });
        })
    } 

    sendEmail() { 
      axios({
          method: "POST",
          url: (process.env.REACT_APP_ENV === "development") ? 'http://localhost:9000/email' : '/email',
          headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
        },
          data: { ...this.state }
      }).then((response)=>{
          if (response.data.msg === 'success'){
              alert("Email sent, thanks!");
          }else if(response.data.msg === 'fail'){
              alert("Oops, something went wrong. Try again")
          }
      })
    }
    
    handleChange(event) {
      event.preventDefault();
      let stateCopy = this.state;
      let name = event.target.name;
      let value = event.target.value;
      stateCopy[name] = value;

      stateCopy["aveDolPerInstall"] = stateCopy["dolPerKw"]*stateCopy["aveKwPerInstall"];
      stateCopy["installsNeeded"] = stateCopy["income"]/stateCopy["aveDolPerInstall"];
      stateCopy["salesNeeded"] = stateCopy["installsNeeded"]/(stateCopy["stickPercentage"]/100);
      stateCopy["sitsNeeded"] = stateCopy["sitsPerSale"]*stateCopy["salesNeeded"];
      stateCopy["apptsNeeded"] = stateCopy["apptsPerSit"]*stateCopy["sitsNeeded"];
      stateCopy["convsNeeded"] = stateCopy["convsPerApt"]*stateCopy["apptsNeeded"];
      stateCopy["doorsNeeded"] = stateCopy["convsNeeded"]*stateCopy["doorsPerConvs"];
      stateCopy["hoursNeeded"] = stateCopy["doorsNeeded"]/stateCopy["doorsPerHour"];
      stateCopy["hoursPerWeek"] = stateCopy["hoursNeeded"]/52;
      stateCopy["hoursPerDay"] = stateCopy["hoursPerWeek"]/6;

      var currentdate = new Date(); 
      var date = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/" 
              + currentdate.getFullYear();
              
      var time = currentdate.getHours() + ":"  
              + currentdate.getMinutes() + ":" 
              + currentdate.getSeconds();

      stateCopy["date"] = date;
      stateCopy["time"] = time;
      this.setState(stateCopy)
    }

    render() {
        return (
          <div>
            <form
              name="sentMessage"
              id="contactForm1"
              noValidate
              onSubmit={this.handleSubmit}
            >
              <div className="form-group">
                <label>Full Name: </label>
                <input
                  name="username"
                  type="text"
                  id="username"
                  className="form-control"
                  placeholder="Full Name"
                  required="required"
                  value={this.state["username"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Income Goal (1099 Amt): </label>
                <input
                  name="income"
                  type="number"
                  id="income"
                  className="form-control"
                  placeholder="Income Goal (1099 Amt)"
                  required="required"
                  value={this.state["income"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>$/KW: </label>
                <input
                  name="dolPerKw"
                  type="number"
                  id="dolPerKw"
                  className="form-control"
                  placeholder="$/KW"
                  required="required"
                  value={this.state["dolPerKw"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Avg KW/Install: </label>
                <input
                  name="aveKwPerInstall"
                  type="number"
                  id="aveKwPerInstall"
                  className="form-control"
                  placeholder="Avg KW/Install"
                  required="required"
                  value={this.state["aveKwPerInstall"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Stick Percentage: </label>
                <input
                  name="stickPercentage"
                  type="number"
                  id="stickPercentage"
                  className="form-control"
                  placeholder="Stick Percentage"
                  required="required"
                  value={this.state["stickPercentage"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Sits Per Sale: </label>
                <input
                  name="sitsPerSale"
                  type="number"
                  id="sitsPerSale"
                  className="form-control"
                  placeholder="Sits Per Sale"
                  required="required"
                  value={this.state["sitsPerSale"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Appointments Per Sit: </label>
                <input
                  name="apptsPerSit"
                  type="number"
                  id="apptsPerSit"
                  className="form-control"
                  placeholder="Appointments Per Sit"
                  required="required"
                  value={this.state["apptsPerSit"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Conversations Per Appt: </label>
                <input
                  name="convsPerApt"
                  type="number"
                  id="convsPerApt"
                  className="form-control"
                  placeholder="Convserations Per Appt"
                  required="required"
                  value={this.state["convsPerApt"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Doors Per Convseration: </label>
                <input
                  name="doorsPerConvs"
                  type="number"
                  id="doorsPerConvs"
                  className="form-control"
                  placeholder="Doors Per Convseration"
                  required="required"
                  value={this.state["doorsPerConvs"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>Doors Knocked Per Hour: </label>
                <input
                  name="doorsPerHour"
                  type="number"
                  id="doorsPerHour"
                  className="form-control"
                  placeholder="Doors Per Hour"
                  required="required"
                  value={this.state["doorsPerHour"]}
                  onChange={this.handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>

              <div className="form-group">
                <label>My email: </label>
                <input
                  name="receiverEmail"
                  type="text"
                  id="receiverEmail"
                  className="form-control"
                  placeholder="My email"
                  required="required"
                  value={this.state["receiverEmail"]}
                  onChange={this.handleChange}
                />
              </div>
            </form>

            <div>
              <table>
                <tr>
                  <th>Sales Goals Worksheet Inputs</th>
                  <th>Your Factors</th>
                  <th>Conservative Factors</th>
                  <th>Possible Factors w/great training</th>
                </tr>
                <tr>
                  <td>Income Goal (1099 Amt)</td>
                  <td>{this.state.income}</td>
                  <td>400000</td>
                  <td>638000</td>
                </tr>
                <tr>
                  <td>$/KW</td>
                  <td>{this.state.dolPerKw}</td>
                  <td>300</td>
                  <td>400</td>
                </tr>
                <tr>
                  <td>Avg KW/Install</td>
                  <td>{this.state.aveKwPerInstall}</td>
                  <td>10</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Stick Percentage</td>
                  <td>{this.state.stickPercentage}</td>
                  <td>80</td>
                  <td>90</td>
                </tr>
                <tr>
                  <td>Sits Per Sale</td>
                  <td>{this.state.sitsPerSale}</td>
                  <td>4</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Appts Per Sit</td>
                  <td>{this.state.apptsPerSit}</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Conversations Per Appt</td>
                  <td>{this.state.convsPerApt}</td>
                  <td>10</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Doors Per Conversation</td>
                  <td>{this.state.doorsPerConvs}</td>
                  <td>3</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Doors Knocked Per Hour</td>
                  <td>{this.state.doorsPerHour}</td>
                  <td>25</td>
                  <td>25</td>
                </tr>
              </table>
            </div>

            <br></br>

            <div>
              <table>
                <tr>
                  <th>Sales Goals Worksheet Outputs</th>
                  <th>Your Numbers</th>
                  <th>Conservative Numbers</th>
                  <th>Possible Numbers w/great training</th>
                </tr>
                <tr>
                  <td>AVG $$/Install</td>
                  <td>{Math.round(this.state.aveDolPerInstall)}</td>
                  <td>3000</td>
                  <td>4800</td>
                </tr>
                <tr>
                  <td>Installs Needed</td>
                  <td>{Math.round(this.state.installsNeeded)}</td>
                  <td>133</td>
                  <td>133</td>
                </tr>
                <tr>
                  <td>Sales Needed</td>
                  <td>{Math.round(this.state.salesNeeded)}</td>
                  <td>167</td>
                  <td>148</td>
                </tr>
                <tr>
                  <td>Sits Needed</td>
                  <td>{Math.round(this.state.sitsNeeded)}</td>
                  <td>667</td>
                  <td>295</td>
                </tr>
                <tr>
                  <td>Appointments Needed</td>
                  <td>{Math.round(this.state.apptsNeeded)}</td>
                  <td>2000</td>
                  <td>591</td>
                </tr>
                <tr>
                  <td>Conversations Needed</td>
                  <td>{Math.round(this.state.convsNeeded)}</td>
                  <td>20000</td>
                  <td>5907</td>
                </tr>
                <tr>
                  <td>Door Knocks Needed</td>
                  <td>{Math.round(this.state.doorsNeeded)}</td>
                  <td>60000</td>
                  <td>17722</td>
                </tr>
                <tr>
                  <td>Hours Needed</td>
                  <td>{Math.round(this.state.hoursNeeded)}</td>
                  <td>2400</td>
                  <td>709</td>
                </tr>
                <tr>
                  <td>Hours Per Week</td>
                  <td>{Math.round(this.state.hoursPerWeek)}</td>
                  <td>46</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>Hours Per Day</td>
                  <td>{Math.round(this.state.hoursPerDay)}</td>
                  <td>8</td>
                  <td>3</td>
                </tr>
              </table>
            </div>

            <h3>
              Please save your factors and numbers before leaving. You can save
              more than one set:
            </h3>
            <button onClick={this.sendData} className="btn btn-custom btn-lg">
              Save Results to Cloud
            </button>

            <h3>Want a personal copy of your data for later?:</h3>

            <button onClick={this.sendEmail} className="btn btn-custom btn-lg">
              Email Me My Results
            </button>
          </div>
        );
    }
}

export default Goals;
